import PropTypes from "prop-types";
import * as React from "react";
import Media from "../Common/Media";
import Link from "../UI/Link";
import * as styles from "./cases-card.module.css";

const CasesCard = ({link, caseContent, logo}) => {
    return (
        <div className={styles.casesCard}>
            <Media media={{content: logo, youtubeVideoURL: ""}} className={styles.casesCard__image} />
            <div className={styles.casesCard__content}>
                {caseContent.map(({metric, desc}) => (
                    <div>
                        <h3 className={styles.casesCard__metric}>{metric}</h3>
                        <p className={styles.casesCard__desc}>{desc}</p>
                    </div>
                ))}
            </div>
            <Link to={link} withArrow>
                Читать кейс
            </Link>
        </div>
    );
};

CasesCard.propTypes = {
    link: PropTypes.string,
    logo: PropTypes.shape({
        mime: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        localFile: PropTypes.shape({
            publicURL: PropTypes.string,
            childImageSharp: PropTypes.shape({
                gatsbyImageData: PropTypes.shape({}),
            }),
        }),
    }),
    caseContent: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            desc: PropTypes.string,
            metric: PropTypes.string,
        }),
    ),
};

export default CasesCard;
