import PropTypes from "prop-types";
import * as React from "react";
import Section from "../Layout/Section";
import Media from "../Common/Media";
import * as advantagesStyles from "./advantages.module.css";

const Advantages = ({title, description, list}) => {
    return (
        <Section title={title} desc={description}>
            <div className={advantagesStyles.advantages__list}>
                {list.map((entry, idx) => {
                    return (
                        <div key={idx}>
                            <Media
                                media={{content: entry.icon, youtubeVideoURL: ""}}
                                className={advantagesStyles.advantages__itemIcon}
                            />
                            <h4 className={advantagesStyles.advantages__itemTitle}>{entry.title}</h4>
                            <p className={advantagesStyles.advantages__itemDesc}>{entry.description}</p>
                        </div>
                    );
                })}
            </div>
        </Section>
    );
};

Advantages.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            icon: PropTypes.shape({
                mime: PropTypes.string.isRequired,
                width: PropTypes.number.isRequired,
                height: PropTypes.number.isRequired,
                localFile: PropTypes.shape({
                    publicURL: PropTypes.string,
                    childImageSharp: PropTypes.shape({
                        gatsbyImageData: PropTypes.shape({}),
                    }),
                }),
            }).isRequired,
        }).isRequired,
    ).isRequired,
};

export default Advantages;
