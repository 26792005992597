import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import ContentBlock from "../Layout/ContentBlock";
import List from "../UI/List";
import CheckMarker from "../UI/List/CheckMarker";
import * as helpdeskStyles from "./helpdesk.module.css";

const HelpDesk = () => {
    const title = "Хелп деск с доказанной эффективностью и кастомной настройкой";

    const getLeftContent = () => {
        const listItems = [
            "0 потерянных заявок",
            "В 5 раз меньше срок обработки заявок",
            "-25% просроченных заявок",
            "-30% расходов на оплату труда",
            "+30% рост производительности сотрудников",
            "До 99,9% рост удовлетворенности клиентов",
        ];

        const marker = CheckMarker();

        return (
            <div>
                <List elements={listItems} marker={marker} />
            </div>
        );
    };

    const getRightContent = () => {
        return (
            <div className={helpdeskStyles.helpdesk__imageWrapper}>
                <StaticImage
                    src="../../assets/images/home/helpdesk/helpdesk.png"
                    alt=""
                    className={helpdeskStyles.helpdesk__image}
                />
                <StaticImage
                    src="../../assets/images/home/helpdesk/desktop.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    className={helpdeskStyles.helpdesk__gradient}
                />
                <StaticImage
                    src="../../assets/images/home/helpdesk/tablet.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    className={`${helpdeskStyles.helpdesk__gradient} ${helpdeskStyles.helpdesk__gradient_tablet}`}
                />
                <StaticImage
                    src="../../assets/images/home/helpdesk/mobile.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    className={`${helpdeskStyles.helpdesk__gradient} ${helpdeskStyles.helpdesk__gradient_mobile}`}
                />
            </div>
        );
    };

    return <ContentBlock alignLeft title={title} rightContent={getRightContent()} leftContent={getLeftContent()} />;
};

export default HelpDesk;
