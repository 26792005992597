import PropTypes from "prop-types";
import * as React from "react";
import Section from "../Layout/Section";
import SectionTitle from "../Layout/Section/Title";
import Media from "../Common/Media";
import * as clientsStyles from "./clients.module.css";

const Clients = ({clients}) => {
    return (
        <Section>
            <div className={clientsStyles.clients}>
                <div className={clientsStyles.clients__desc}>
                    <SectionTitle>Нам доверяют 400+ компаний</SectionTitle>
                </div>
                <div className={clientsStyles.clients__logoList}>
                    {clients.map((clientData, idx) => {
                        const {image} = clientData;

                        return (
                            image && (
                                <Media
                                    key={idx}
                                    media={{content: image, youtubeVideoURL: ""}}
                                    className={clientsStyles.clients__logoWrapper}
                                    imageProps={{
                                        loading: "lazy",
                                        imgClassName: clientsStyles.clients__logo,
                                        className: clientsStyles.clients__logoWrapper,
                                    }}
                                />
                            )
                        );
                    })}
                </div>
            </div>
        </Section>
    );
};

Clients.propTypes = {
    clients: PropTypes.arrayOf(
        PropTypes.exact({
            image: PropTypes.shape({
                mime: PropTypes.string.isRequired,
                width: PropTypes.number.isRequired,
                height: PropTypes.number.isRequired,
                localFile: PropTypes.shape({
                    publicURL: PropTypes.string,
                    childImageSharp: PropTypes.shape({
                        gatsbyImageData: PropTypes.shape({}),
                    }),
                }),
            }),
        }),
    ).isRequired,
};

export default Clients;
