// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--Nn-Pe";
export var hero__title = "hero-module--hero__title--fS4gx";
export var hero__media = "hero-module--hero__media--6w2j-";
export var hero__gradient = "hero-module--hero__gradient--B-QyO";
export var hero__gradient_laptop = "hero-module--hero__gradient_laptop--9G9+Y";
export var hero__gradient_tablet = "hero-module--hero__gradient_tablet--ADUfX";
export var hero__gradient_mobile = "hero-module--hero__gradient_mobile--n-j8g";
export var hero_textLeft = "hero-module--hero_text-left--Wj8gO";
export var hero__button = "hero-module--hero__button--AOqDA";
export var hero__gradient_desktop = "hero-module--hero__gradient_desktop--7AYTP";