import PropTypes from "prop-types";
import * as React from "react";
import Section from "../Layout/Section";
import Slider from "../Common/Slider";
import SliderPagination from "../Common/Slider/Pagination";
import SliderNavigation from "../Common/Slider/Navigation";
import TestimonialCard from "./Card";
import * as testimonialsStyles from "./testimonials-slider.module.css";

const TestimonialsSlider = ({testimonials}) => {
    return (
        <Section>
            <Slider
                sliderClassName={testimonialsStyles.testimonialsSlider}
                containerClassName={testimonialsStyles.testimonialsSlider__container}
                title="Что наши клиенты говорят о сервисе"
                navigation={<SliderNavigation />}
                pagination={<SliderPagination />}
            >
                {testimonials.map((data, idx) => (
                    <TestimonialCard key={idx} {...data} />
                ))}
            </Slider>
        </Section>
    );
};

TestimonialsSlider.propTypes = {
    testimonials: PropTypes.arrayOf(
        PropTypes.exact({
            testimonial: PropTypes.string.isRequired,
            author: PropTypes.string.isRequired,
            logo: PropTypes.shape({
                mime: PropTypes.string.isRequired,
                width: PropTypes.number.isRequired,
                height: PropTypes.number.isRequired,
                localFile: PropTypes.shape({
                    publicURL: PropTypes.string,
                    childImageSharp: PropTypes.shape({
                        gatsbyImageData: PropTypes.shape({}),
                    }),
                }),
            }),
        }),
    ).isRequired,
};

export default TestimonialsSlider;
