import classNames from "classnames/bind";
import PropTypes from "prop-types";
import * as React from "react";
import * as listStyles from "./list.module.css";

const cx = classNames.bind(listStyles);

const List = ({ordered, elements, marker}) => {
    const getChildren = () => {
        return elements.map((element, idx) => {
            return (
                <li className={listStyles.list__item} key={idx}>
                    {marker && <span className={listStyles.list__marker}>{marker}</span>} <span>{element}</span>
                </li>
            );
        });
    };

    const classes = cx({
        list: true,
        list_ordered: ordered,
        list_customMarker: marker,
    });

    return ordered ? <ol className={classes}>{getChildren()}</ol> : <ul className={classes}>{getChildren()}</ul>;
};

List.defaultProps = {
    ordered: false,
    elements: [],
    marker: null,
};

List.propTypes = {
    ordered: PropTypes.bool,
    elements: PropTypes.arrayOf(PropTypes.node),
    marker: PropTypes.element,
};

export default List;
