import PropTypes from "prop-types";
import * as React from "react";
import Slider from "../Common/Slider";
import * as casesStyles from "./cases-list.module.css";
import SliderPagination from "../Common/Slider/Pagination";
import Section from "../Layout/Section";
import CasesCard from "./Card";

const CasesList = ({title, cases}) => {
    return (
        <Section className={casesStyles.casesList}>
            <Slider
                title={title}
                pagination={<SliderPagination />}
                sliderClassName={casesStyles.casesList__slider}
                containerClassName={casesStyles.casesList__container}
            >
                {cases.map((data, idx) => (
                    <CasesCard key={data.id || idx} {...data} />
                ))}
            </Slider>
        </Section>
    );
};

CasesList.propTypes = {
    title: PropTypes.string,
    cases: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            link: PropTypes.string,
            logo: PropTypes.shape({
                mime: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
                localFile: PropTypes.shape({
                    publicURL: PropTypes.string,
                    childImageSharp: PropTypes.shape({
                        gatsbyImageData: PropTypes.shape({}),
                    }),
                }),
            }),
            caseContent: PropTypes.arrayOf(
                PropTypes.exact({
                    id: PropTypes.string,
                    desc: PropTypes.string,
                    metric: PropTypes.string,
                }),
            ),
        }),
    ).isRequired,
};

export default CasesList;
