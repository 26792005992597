import classNames from "classnames/bind";
import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import {useCallback} from "react";
import * as React from "react";
import Container from "../../Layout/Container";
import Button from "../../UI/Button";
import Media from "../../Common/Media";
import TemplateString from "../../Common/TemplateString";
import * as heroStyles from "./hero.module.css";

const cx = classNames.bind(heroStyles);

const Hero = ({title, button_text: buttonText, media, variant = "text_above"}) => {
    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#free-demo-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({behavior: "smooth"});
    }, []);

    return (
        <Container className={cx({hero: true, hero_textLeft: variant === "text_left"})}>
            <h1 className={heroStyles.hero__title}>
                <TemplateString template={title} />
            </h1>
            <Button variant="primary" className={heroStyles.hero__button} onClick={scrollToForm}>
                {buttonText}
            </Button>{" "}
            {media && (
                <div className={heroStyles.hero__media}>
                    <Media media={media} big={variant !== "text_left"} imageProps={{loading: "eager"}} />
                </div>
            )}
            <div>
                <StaticImage
                    src="../../../assets/images/home/hero/desktop.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_desktop}`}
                />
                <StaticImage
                    src="../../../assets/images/home/hero/laptop.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_laptop}`}
                />
                <StaticImage
                    src="../../../assets/images/home/hero/tablet.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_tablet}`}
                />
                <StaticImage
                    src="../../../assets/images/home/hero/mobile.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_mobile}`}
                />
            </div>
        </Container>
    );
};

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    button_text: PropTypes.string.isRequired,
    media: PropTypes.exact({
        youtubeVideoURL: PropTypes.string,
        content: PropTypes.shape({
            mime: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
            localFile: PropTypes.shape({
                publicURL: PropTypes.string,
                childImageSharp: PropTypes.shape({
                    gatsbyImageData: PropTypes.shape({}),
                }),
            }),
        }),
    }).isRequired,
    variant: PropTypes.string.isRequired,
};

export default Hero;
