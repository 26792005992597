import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/Layout/layout";
import Container from "../components/Layout/Container";
import Seo from "../components/SEO/SEO";
import Hero from "../components/Home/Hero";
import Products from "../components/Home/Products";
import Clients from "../components/Home/Clients";
import Advantages from "../components/Home/Advantages";
import HelpDesk from "../components/Home/HelpDesk";
import Integrations from "../components/Home/Integrations";
import TestimonialsSlider from "../components/Testimonials/Slider";
import Blog from "../components/Home/Blog";
import FreeDemoForm from "../components/Form/FreeDemo";
import CasesList from "../components/Cases/List";
import * as homeStyles from "./index.module.css";

const IndexPage = ({data: {mainPage, articles}}) => {
    const {
        hero,
        our_products: {clients, ...products},
        advantages,
        testimonials,
        integrations,
        seo,
        hideTestimonials = false,
        casesBlock,
    } = mainPage;

    return (
        <Layout>
            <Seo seo={seo} />
            <Hero {...hero} />
            <Products {...products} />
            <Clients clients={clients} />
            <Advantages {...advantages} />
            <HelpDesk />
            <Integrations {...integrations} />
            <div>
                {!hideTestimonials && <TestimonialsSlider testimonials={testimonials} />}
                {!!casesBlock && !!casesBlock.cases && casesBlock.cases.length > 0 && (
                    <CasesList cases={casesBlock.cases} title={casesBlock.title} />
                )}
                <Container className={homeStyles.gradientWrapper}>
                    <StaticImage
                        src="../assets/images/home/testimonials/desktop.png"
                        alt=""
                        layout="fixed"
                        placeholder="none"
                        className={homeStyles.gradient}
                    />
                    <StaticImage
                        src="../assets/images/home/testimonials/tablet.png"
                        alt=""
                        layout="fixed"
                        placeholder="none"
                        className={`${homeStyles.gradient} ${homeStyles.gradient_tablet}`}
                    />
                    <StaticImage
                        src="../assets/images/home/testimonials/mobile.png"
                        alt=""
                        layout="fixed"
                        placeholder="none"
                        className={`${homeStyles.gradient} ${homeStyles.gradient_mobile}`}
                    />
                </Container>
            </div>
            <Blog articles={articles} />
            <FreeDemoForm />
        </Layout>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    query {
        mainPage: strapiMain {
            hideTestimonials
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            hero {
                button_text
                title
                media {
                    youtubeVideoURL
                    content {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                }
                variant
            }
            our_products {
                title
                description
                products {
                    title: shortTitle
                    short_description
                    type
                    slug
                }
                clients {
                    image {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                }
            }
            advantages {
                title
                description
                list {
                    title
                    description
                    icon {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                }
            }
            testimonials {
                testimonial
                author
                logo {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                }
            }
            integrations {
                title
                description
                media {
                    youtubeVideoURL
                    content {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                }
            }
            casesBlock {
                title
                cases {
                    id
                    link
                    logo {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                    caseContent {
                        id
                        metric
                        desc
                    }
                }
            }
        }
        articles: allStrapiArticles(limit: 3, sort: {fields: published_at, order: DESC}) {
            edges {
                node {
                    title
                    description
                    readingTime
                    published_at
                    slug
                    tags: articleTags {
                        title
                        slug
                    }
                }
            }
        }
    }
`;
