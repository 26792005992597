import PropTypes from "prop-types";
import * as React from "react";
import ContentBlock from "../Layout/ContentBlock";
import Media from "../Common/Media";

const Integrations = ({title, description, media}) => {
    return (
        <ContentBlock
            alignLeft
            title={title}
            desc={description}
            rightContent={media && <Media media={media} />}
            reversed
        />
    );
};

Integrations.defaultProps = {
    title: "",
    description: "",
};

Integrations.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    media: PropTypes.shape({
        youtubeVideoURL: PropTypes.string,
        content: PropTypes.shape({
            mime: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
            localFile: PropTypes.shape({
                publicURL: PropTypes.string,
                childImageSharp: PropTypes.shape({
                    gatsbyImageData: PropTypes.shape({}),
                }),
            }),
        }),
    }),
};

export default Integrations;
